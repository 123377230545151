import type {FC} from 'react';
import Link from 'next/link';

import type {ButtonSize, ButtonVariant} from '@/components/button';
import {Button} from '@/components/button';

export type CallToActionProps = {
	href: string;
	targetBlank?: boolean;
	text: string;
	color?: ButtonVariant;
	size?: ButtonSize;
};

export const CallToAction: FC<CallToActionProps> = ({href, targetBlank, text, color, size}) => {
	return (
		<Button asChild size={size} variant={color}>
			<Link href={href} {...(targetBlank && {target: '_blank'})}>
				{text}
			</Link>
		</Button>
	);
};
