import type {FC} from 'react';
import React from 'react';

import type {KeyedArray} from '@/shared/types';

import type {ContainerSpacing} from '../../container';

import type {LinkInCard} from './LinkCardGrid';
import LinkCardGrid from './LinkCardGrid';

type Props = {
	heading?: string;
	tagline?: string;
	links?: KeyedArray<LinkInCard>;
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
};

export const LinkListSelection: FC<Props> = ({...props}) => {
	return <LinkCardGrid {...props} />;
};
