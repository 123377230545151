import type {FC} from 'react';

import {
	TextWithImageSplit,
	textWithImageSplitPortableTextOverrides,
} from '@/components/content-block/text-with-image-split';
import type {ImageWeb} from '@/components/image/types';
import {PortableText} from '@/sanity/components/portable-text';

import {imageWebFromImageWebSchema} from '../../image/query';

import type {TextWithImageSplitFragment} from './query';

interface Props {
	data: TextWithImageSplitFragment;
}

export const TextWithImageSplitContainer: FC<Props> = ({data}) => {
	let image: ImageWeb | undefined;

	if (data.image?.asset?._ref) {
		image = imageWebFromImageWebSchema(data.image);
	}

	return (
		<TextWithImageSplit
			imageAlignment={data.alignment}
			image={image}
			spacingTop={data.layout?.spacingTop}
			spacingBottom={data.layout?.spacingTop}
			heading={data.title}
		>
			{data.content && (
				<PortableText
					blocks={data.content}
					overrideClassNames={textWithImageSplitPortableTextOverrides}
				/>
			)}
		</TextWithImageSplit>
	);
};
