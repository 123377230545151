import {groq} from 'next-sanity';

import type {GallerySchema} from '@/sanity/components/content-blocks/gallery-container/schema';
import type {LinkListSelectionFragmentType} from '@/sanity/components/content-blocks/link-list-selection-container/query';
import {LINK_LIST_SELECTION_FRAGMENT} from '@/sanity/components/content-blocks/link-list-selection-container/query';
import {linkListSelection} from '@/sanity/components/content-blocks/link-list-selection-container/schema';

import type {TextSectionFragmentType} from './text-container/query';
import {TEXT_SECTION_FRAGMENT} from './text-container/query';
import {text} from './text-container/schema';
import type {TextWithImageSplitFragment} from './text-with-image-split-container/query';
import {TEXT_WITH_IMAGE_SPLIT_FRAGMENT} from './text-with-image-split-container/query';
import {textWithImageSplit} from './text-with-image-split-container/schema';

export const CONTENT_BLOCK_FRAGMENT = groq`
	...,

	// Klart foundation
	_type == "${text().name}" => {
		${TEXT_SECTION_FRAGMENT}
	},
	_type == "${textWithImageSplit().name}" => {
		${TEXT_WITH_IMAGE_SPLIT_FRAGMENT}
	},
	_type == "${linkListSelection().name}" => {
		${LINK_LIST_SELECTION_FRAGMENT}
	},
`;

export type ContentBlockFragment =
	| TextSectionFragmentType
	| TextWithImageSplitFragment
	| GallerySchema
	| LinkListSelectionFragmentType;
