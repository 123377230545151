import type {ComponentPropsWithoutRef} from 'react';
import {forwardRef} from 'react';
import {Slot} from '@radix-ui/react-slot';

import {cn} from '@/shared';

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant = 'primary' | 'secondary';

export type ButtonProps = ComponentPropsWithoutRef<'button'> & {
	/** Specify which variant to use */
	variant?: ButtonVariant;
	/** Size */
	size?: ButtonSize;
	// /** If `Button` should fill full width of its container */
	// fullWidth?: boolean;
	// /** Toggle icon only styling, pass icon as children
	//  * @default false
	//  */
	// icon?: boolean;
	/**
	 * Change the default rendered element for the one passed as a child, merging their props and behavior.
	 * @default false
	 */
	asChild?: boolean;
};

/**
 * Button used for interaction
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			children,
			variant = 'primary',
			size = 'medium',
			// fullWidth = false,
			// icon = false,
			type = 'button',
			className,
			asChild,
			...rest
		},
		ref,
	) => {
		const Component = asChild ? Slot : 'button';

		return (
			<Component
				ref={ref}
				type={type}
				className={cn([
					'no-underline',
					'inline-flex',
					'items-center',
					'text-center',
					'justify-center',
					'gap-2',
					'border',
					'border-primary-dark',
					'text-base',
					'font-bold',
					'rounded-full',
					variant === 'primary' && ['bg-primary-dark', 'text-primary-light'],
					variant === 'secondary' && ['bg-primary-light', 'text-primary-dark'],
					size === 'small' && ['px-2', 'py-1'],
					size === 'medium' && ['px-4', 'py-2'],
					size === 'large' && ['px-6', 'py-3'],
					className,
				])}
				{...rest}
			>
				{children}
			</Component>
		);
	},
);

Button.displayName = 'Button';
