import type {FC, PropsWithChildren} from 'react';

import type {PortableTextOverrides} from '@/sanity/components/portable-text';

import type {ContainerSpacing} from '../../container';
import {Container} from '../../container';

type Props = PropsWithChildren & {
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
};

export const textPortableTextOverrides: PortableTextOverrides = {};

export const Text: FC<Props> = ({children, spacingBottom, spacingTop}) => {
	return (
		<Container width="text" spacingBottom={spacingBottom} spacingTop={spacingTop}>
			{children}
		</Container>
	);
};
