import type {FC} from 'react';
import React from 'react';
import clsx from 'clsx';
import type {ControlProps} from 'nuka-carousel';

import {ArrowLeftIcon} from '@/components/icons/arrow-left-icon';
import {ArrowRightIcon} from '@/components/icons/arrow-right-icon';

const buttonBase = clsx(
	'border-none',
	'bg-transparent',
	'cursor-pointer',
	'disabled:cursor-not-allowed',
	'flex',
	'appearance-none',
	'items-center',
	'text-white',
	'opacity-70',
	'hover:opacity-100',
	'disabled:opacity-30',
);

export const renderCenterLeftControls: FC<ControlProps> = ({previousDisabled, previousSlide}) => (
	<button
		className={clsx(
			buttonBase,
			'absolute',
			'max-sm:left-[20px]',
			'sm:right-full',
			'max-sm:top-full',
		)}
		disabled={previousDisabled}
		onClick={previousSlide}
		aria-label="Gå til forrige slide"
	>
		<ArrowLeftIcon color="white" />
	</button>
);

export const renderCenterRightControls: FC<ControlProps> = ({nextDisabled, nextSlide}) => (
	<button
		className={clsx(
			buttonBase,
			'absolute',
			'max-sm:right-[20px]',
			'sm:left-full',
			'max-sm:top-full',
		)}
		disabled={nextDisabled}
		onClick={nextSlide}
		aria-label="Gå til neste slide"
	>
		<ArrowRightIcon color="white" />
	</button>
);
