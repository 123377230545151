import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import {Container} from '@/components/container';
import {Heading} from '@/components/typography/heading';
import type {PortableTextOverrides} from '@/sanity/components/portable-text';

type Props = PropsWithChildren & {
	heading?: string;
};

export const textHeadingPortableTextOverrides: PortableTextOverrides = {
	p: 'text-xl md:text-2xl',
};

export const TextHeading: FC<Props> = ({heading, children}) => {
	return (
		<Container className={clsx('py-24', 'md:py-40', 'bg-secondary-light')}>
			<div className={clsx('max-w-3xl', 'text-primary-dark')}>
				<Heading spacing>{heading}</Heading>
				{children}
			</div>
		</Container>
	);
};
