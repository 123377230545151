import type {FC, PropsWithChildren} from 'react';

import {LinkListSelection} from '@/components/content-block/link-list-selection';
import type {LinkInCard} from '@/components/content-block/link-list-selection/LinkCardGrid';
import {type ImageWeb} from '@/components/image/types';
import {resolveLinkHref} from '@/shared';
import type {KeyedArray} from '@/shared/types';

import {imageWebFromImageWebSchema} from '../../image/query';

import type {LinkListSelectionFragmentType} from './query';

type Props = PropsWithChildren & {
	data: LinkListSelectionFragmentType;
};

export const LinkListSelectionContainer: FC<Props> = ({data}) => {
	const links: KeyedArray<LinkInCard> = [];

	if (data.links) {
		for (const link of data.links) {
			let image: ImageWeb | undefined;
			if (link.image?.asset?._ref) {
				image = imageWebFromImageWebSchema(link.image);
			}

			links.push({
				_key: link._key,
				href: resolveLinkHref(link),
				title: link.title ?? 'Mangler tittel...',
				image: image,
				imageAltText: link.image?.altText,
			});
		}
	}

	return (
		<LinkListSelection
			spacingBottom={data.layout?.spacingBottom}
			spacingTop={data.layout?.spacingTop}
			heading={data.heading}
			tagline={data.tagline}
			links={links}
		/>
	);
};
