import type {FC, PropsWithChildren} from 'react';
import clsx from 'clsx';

import {ImageBlock} from '@/components/image/ImageBlock';
import type {ImageWeb} from '@/components/image/types';
import {Heading} from '@/components/typography/heading';
import type {PortableTextOverrides} from '@/sanity/components/portable-text';

import type {ContainerSpacing} from '../../container';
import {Container} from '../../container';

type Props = PropsWithChildren<{
	heading?: string;
	image?: ImageWeb;
	imageAlignment?: 'left' | 'right';
	spacingTop?: ContainerSpacing;
	spacingBottom?: ContainerSpacing;
}>;

export const textWithImageSplitPortableTextOverrides: PortableTextOverrides = {};

export const TextWithImageSplit: FC<Props> = ({
	heading,
	image,
	imageAlignment,
	spacingTop,
	spacingBottom,
	children,
}) => {
	return (
		<Container spacingBottom={spacingBottom} spacingTop={spacingTop}>
			<div
				className={clsx(
					'grid',
					'md:grid-cols-2',
					'gap-6',
					'md:gap-10',
					'text-primary-dark',
					'items-center',
				)}
			>
				<div className={clsx(imageAlignment === 'left' ? 'order-0 md:order-1' : 'order-0')}>
					{heading && (
						<Heading level={2} spacing className={clsx('mb-6')}>
							{heading}
						</Heading>
					)}
					{children}
				</div>

				<div className={clsx('flex', 'items-center')}>
					{image && (
						<ImageBlock
							src={image.image.src}
							width={image.image.width}
							height={image.image.height}
							alt={image.altText ?? ''}
						/>
					)}
				</div>
			</div>
		</Container>
	);
};
