import type {ReactNode} from 'react';
import clsx from 'clsx';

import {Paragraph} from '@/components/typography/paragraph';

type Props = {
	children: ReactNode;
};

export const Blockquote = ({children}: Props) => {
	return (
		<blockquote className={clsx('my-10', 'border-l-[5px]', 'border-primary-medium', 'pl-4')}>
			<Paragraph
				className={clsx('font-display', 'text-[22px]', 'leading-normal', 'md:text-[30px]')}
			>
				<span>“</span>
				{children}
				<span>”</span>
			</Paragraph>
		</blockquote>
	);
};
