'use client';

import type {FC} from 'react';

import {Gallery} from '@/components/content-block/gallery';
import {type ImageWeb} from '@/components/image/types';
import type {KeyedArray} from '@/shared/types';

import {imageWebFromImageWebSchema} from '../../image/query';

import type {GallerySchema} from './schema';

interface Props {
	data?: GallerySchema;
}

export const GalleryContainer: FC<Props> = ({data}) => {
	const {layout, images} = data ?? {};

	let aspectRatio = 3 / 2;

	switch (data?.aspectRatio) {
		case '1:1':
			aspectRatio = 1;
			break;
		case '16:9':
			aspectRatio = 16 / 9;
			break;
		case '3:2':
			aspectRatio = 3 / 2;
			break;
	}

	if (!images || images.length === 0) {
		return null;
	}

	const carouselImages: KeyedArray<ImageWeb> = [];

	for (const sanityImage of images) {
		if (!sanityImage.asset?._ref) {
			continue;
		}

		carouselImages.push({
			_key: sanityImage._key,
			...imageWebFromImageWebSchema(sanityImage, aspectRatio),
		});
	}

	if (!carouselImages || carouselImages.length === 0) {
		return null;
	}

	return (
		<Gallery
			spacingBottom={layout?.spacingBottom}
			spacingTop={layout?.spacingTop}
			images={carouselImages}
		/>
	);
};
