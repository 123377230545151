import type {FC} from 'react';

import {Text, textPortableTextOverrides} from '@/components/content-block/text';
import {PortableText} from '@/sanity/components/portable-text';

import type {TextSectionFragmentType} from './query';

interface Props {
	data: TextSectionFragmentType;
}

export const TextContainer: FC<Props> = ({data}) => {
	const {content, layout} = data ?? {};

	return (
		<Text spacingBottom={layout?.spacingBottom} spacingTop={layout?.spacingTop}>
			<div>
				{content && (
					<PortableText blocks={content} overrideClassNames={textPortableTextOverrides} />
				)}
			</div>
		</Text>
	);
};
